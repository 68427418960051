import "reflect-metadata"

import '$vendor/fontawesome'
import '$vendor/infos'
import "$common/main.css"

import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

import * as initializers from "./initializers"
for (const key in initializers) {
  initializers[key](app)
}

app.mount('#app')
